const state = {
    renewPopShow: true,
}
const mutations = {
    SET_RENEWPOPSHOW: (state, Bln) => {
        state.renewPopShow = Bln
    },
}
export default {
    // 开启命名空间
    namespaced: true,
    mutations,
    state,
}