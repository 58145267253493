import { render, staticRenderFns } from "./nine_boxgrid.vue?vue&type=template&id=66ffa2aa&scoped=true"
import script from "./nine_boxgrid.vue?vue&type=script&lang=js"
export * from "./nine_boxgrid.vue?vue&type=script&lang=js"
import style0 from "./nine_boxgrid.vue?vue&type=style&index=0&id=66ffa2aa&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66ffa2aa",
  null
  
)

export default component.exports