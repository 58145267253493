import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const Login = [
    {
        path: '/resultpayment',
        name: 'Resultpayment',
        component: () => import('../../views/result/Resultpayment.vue')
    },
    {
        path: '/wxMiddlePage',
        name: 'WxMiddlePage',
        component: () => import('../../views/result/wxMiddlePage.vue')
    },
]

export default Login
