<template>
  <div class="dialogHint">
    <van-dialog
      v-model="show"
      :title="dialogTitle"
      show-cancel-button
      :showCancelButton="false"
      theme="round-button"
      confirm-button-color="linear-gradient(145deg, #00C2FF 0%, #0066FF 100%)"
      confirm-button-text="知道了"
      @confirm="confirms"
      width="285"
    >
      <div class="dialogBox">
        <slot name="content"></slot>
      </div>
    </van-dialog>
  </div>
</template>
    
    <script>
export default {
  name: "dialogHint",
  components: {},
  data() {
    return {
      //   dialogShow: true,
      show: false,
    };
  },
  props: {
    dialogShow: {
      default: "",
      type: Boolean,
    },
    dialogTitle: {
      default: "",
      type: String,
    },
  },
  mounted() {
    // this.show=this.dialogShow
  },
  watch: {
    dialogShow(newV, oldV) {
      if (newV) {
        this.show = newV;
      }
    },
  },

  methods: {
    confirms() {
      this.$emit("dialogClic", false); // 为向父组件传递的数据
    },
  },
};
</script>
  <style lang="less" scoped>
.dialogHint {
  /deep/ .van-dialog__header {
    font-size: 16px;
    font-weight: 500;
    color: #333;
  }
  /deep/.van-dialog--round-button .van-dialog__footer {
    position: relative;
    height: auto;
    padding: 9px 66px 16px;
  }
  .dialogBox {
    box-sizing: border-box;
    padding: 10px 20px;
    font-size: 13px;
  }
}
</style>