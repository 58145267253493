
export default {
    // 开启命名空间
    namespaced: true,
    actions: {

    },
    mutations: {

    },
    state: {

    },
    getters: {

    },
}