import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../../views/login/Login.vue'

Vue.use(VueRouter)

const Login = [
    {
        path: '/login',
        name: 'login',
        component: login
    }, {
        path: '/forgotpassword',
        name: 'ForgotPassword',
        component: () => import('../../views/login/ForgotPassword.vue')
    },{
        path: '/retrievepassword',
        name: 'RetrievePassword',
        component: () => import('../../views/login/RetrievePassword.vue')
    },{
        path: '/setPassword',
        name: 'SetPassword',
        component: () => import('../../views/login/SetPassword.vue')
    },
    {
        path: '/enrollJumpPassword',
        name: 'EnrollJumpPassword',
        component: () => import('../../views/login/enrollJumpPassword.vue')
    },
]

export default Login
