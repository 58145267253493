<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
export default {
  setup() {
    // ...
  },
  created() {
    if (
      location.protocol !== "https:" &&
      location.hostname == "www.aochenyun.cn"
    ) {
      location.replace(`https://${location.hostname}${location.pathname}`);
    }
  },
  mounted() {
    this.disabledScale();
  },
  watch: {
    $route: function (to, from) {
      window.scrollTo(0, 0);
      //document.body.scrollTop = 0;
      //document.documentElement.scrollTop = 0;
    },
  },

  methods: {
    // 禁止ios10+页面缩放
    disabledScale() {
      var lastTouchEnd = 0;
      document.addEventListener("touchstart", function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      });
      document.addEventListener(
        "touchend",
        function (event) {
          var now = new Date().getTime();
          if (now - lastTouchEnd <= 300) {
            event.preventDefault();
          }
          lastTouchEnd = now;
        },
        false
      );
      // 阻止双指放大
      document.addEventListener("gesturestart", function (event) {
        event.preventDefault();
      });
    },
    // // 日活埋点
    // getRegWelfare() {
    //   .then((res) => {
    //     if (res.code == 200) {
    //       if (res.data.type == "0") {
    //         this.getSysConfigChannel();
    //       }
    //     }
    //   });
    // },
  },
};
</script>

<style>
/* ::-webkit-scrollbar { display: none; }  */
html,
body {
  height: 100%;
  background-color: #f6f8fb;
}
span {
  font-size: 14px;
}
p {
  font-size: 14px;
}
/* page {
  background-color: #f6f8fb;
} */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #f6f8fb;
  min-height: 100%;
  height: 100%;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
