import Vue from 'vue'
import VueRouter from 'vue-router'
// import ActiveBuy from '../../views/Activetemplate/ActiveBuy.vue'

Vue.use(VueRouter)

const server = [
    {
        path: '/Activetemplate',
        name: 'Activetemplate',
        component: () => import('../../views/Activetemplate/Activetemplate.vue')
    }, 
    // {
    //     path: '/activebuy',
    //     name: 'ActiveBuy',
    //     component: ActiveBuy
    // },
]

export default server
