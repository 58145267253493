import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const server = [
    // 新增购买页 *
    {
        path: '/newcloudphone',
        name: 'NEWCloudPhone',
        meta: {
            keepAlive: true, // 需要被缓存
            isBack: false
        },
        component: () => import('../../views/buy/NEWCloudPhone.vue')
    },
    // 选择购买页
    {
        path: '/AddCloudPhone',
        name: 'AddCloudPhone',
        component: () => import('../../views/buy/AddCloudPhone.vue')
    },
    // 购买记录
    {
        path: '/purchaserecord',
        name: 'PurchaseRecord',
        component: () => import('../../views/buy/PurchaseRecord.vue')
    },
    {
        path: '/resultofpayment',
        name: 'ResultofPayment',
        component: () => import('../../views/buy/ResultofPayment.vue')
    },
    // 续时页 *
    {
        path: '/renewaltime',
        name: 'RenewalTime',
        meta: {
            keepAlive: true, // 需要被缓存
            isBack: false
        },
        component: () => import('../../views/buy/RenewalTime.vue')
    },
    // 定制版购买 *
    // {
    //     path: '/customphone',
    //     name: 'CustomPhone',
    //     meta: {
    //         keepAlive: true, // 需要被缓存
    //         isBack: false
    //     },
    //     component: () => import('../../views/buy/CustomPhone.vue')
    // },
    // 新人体验选择页
    {
        path: '/newcomerexperience',
        name: 'NewcomerExperience',
        component: () => import('../../views/buy/NewcomerExperience.vue')
    },
    // 新人体验购买页 *
    {
        path: '/newexperience',
        name: 'NewExperience',
        meta: {
            keepAlive: true, // 需要被缓存
            isBack: false
        },
        component: () => import('../../views/buy/NewExperience.vue')
    },
    // 限时续费
    {
        path: '/timeIimitrenew',
        name: 'TimeIimitrenew',
        component: () => import('../../views/buy/timeIimitrenew.vue')
    },
    // {
    //     path: '/customizeBuyPhone',
    //     name: 'customizeBuyPhone',
    //     component: () => import('../../views/buy/customizeBuyPhone.vue')
    // },
]

export default server
