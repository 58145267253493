import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const server = [
    {
        path: '/discover',
        name: 'discover',
        meta: {
            keepAlive: true, // 需要被缓存
            isBack: false
        },
        component: () => import('../../views/discover/Discover.vue')
    },
    {
        path: '/discoverDetails',
        name: 'DiscoverDetails',
        component: () => import('../../views/discover/discoverDetails.vue')
    },
]

export default server
