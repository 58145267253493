import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'
import BgsSdk from '../components/BgsSdk.vue'
import VeSdk from '../components/VeSdk.vue'
import NzSdk from '../components/NzSdk.vue'
import mine from './mine/index.js'
import login from './login/index.js'
import discover from './discover/index.js'
import buy from './buy/index.js'
import commission from './commission/index.js'
import activetemplate from './activetemplate/index.js'
import issuingplatform from './issuingplatform/index.js'
import result from './result/index.js'
import activationcode from './Activationcode/index.js'
import dowload from './dowload/index.js'
import Groupbooking from './Groupbooking/index.js'
import batchOf from './batchOf/index'
import activityGather from './activityGather'
import channelPreloaded from './channelPreloaded'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/bgsSdk',
        name: 'BgsSdk',
        component: BgsSdk
    },
    {
        path: '/veSdk',
        name: 'VeSdk',
        component: VeSdk
    },
    {
        path: '/nzSdk',
        name: 'NzSdk',
        component: NzSdk
    },
    ...buy,
    ...mine,
    ...login,
    ...result,
    ...dowload,
    ...discover,
    ...commission,
    ...activetemplate,
    ...issuingplatform,
    ...activationcode,
    ...Groupbooking,
    ...batchOf,
    ...activityGather,
    ...channelPreloaded,
    {
        name: '404',
        path: '/404',
        component: () => import('../views/notFound.vue')
      },
    {
        path: '*',    // 此处需特别注意至于最底部
        redirect: '/404'
    }

]

const router = new VueRouter({
    routes,
    mode: 'history'
})

export default router
