import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const server = [
    // {
    //     path: '/springFestival',
    //     name: 'SpringFestival',
    //     component: () => import('../../views/activityGather/springFestival/index.vue')
    // },
    {
        path: '/attemptPlay',
        name: 'AttemptPlay',
        component: () => import('../../views/activityGather/attemptPlay/index.vue')
    },
    {
        path: '/trySdk',
        name: 'TrySdk',
        component: () => import('../../views/activityGather/attemptPlay/trySdk.vue')
    },
    {
        path: '/DNFgroupoid',
        name: 'DNFgroupoId',
        component: () => import('../../views/activityGather/DNFgroupoid')
    },
    // {
    //     path: '/sixOneEightFestival',
    //     name: 'SixOneEightFestival',
    //     component: () => import('../../views/activityGather/sixOneEightFestival')
    // },
]

export default server