<template>
  <div class="Aochen_signIn">
    <!-- 设置 -->
    <van-popup
      v-model="show"
      round
      closeable
      position="bottom"
      class="setting_tc"
      @close="closePopup"
    >
      <div class="homeBox">
        <img
          class="nameImg"
          src="../../assets/img/homeAmend/signIn/name.png"
          alt=""
        />
        <div class="homeRight">
          <img
            class="headImg"
            src="../../assets/img/homeAmend/signIn/headImg.png"
            alt=""
          />
          <div class="addUpBox">
            已累计获得{{ signInObj.accumulateGainDuration / 3600 }}小时
          </div>
        </div>
      </div>
      <div class="content">
        <div class="scheduleBox">
          <div class="addUpDayBox">
            <div class="addUp">累计连续签到：</div>
            <div class="Day">{{ signInObj.accumulateSignNumber }}</div>
          </div>
          <div class="scheduleContent">
            <div
              class="scheduleList"
              v-for="item in weekTotalList"
              :key="item.id"
            >
              <div class="scheduleListTop">
                <div
                  :class="
                    item.name.length != 3 ? 'extra' : 'extra specialextra'
                  "
                >
                  额外
                </div>
                <img class="redPacketImg" :src="item.img" alt="" />
                <div
                  :class="[
                    'dayName',
                    item.name.length != 3 ? 'specialName' : '',
                  ]"
                >
                  {{ item.name }}
                </div>
              </div>
              <div class="progressBar">
                <!-- 进度点点 -->
                <div
                  :class="[
                    'circle',
                    item.id > signInObj.accumulateSignNumber ? 'lessThan' : '',
                  ]"
                ></div>
                <!-- 进度条 -->
                <div
                  :class="[
                    'line',
                    item.id > signInObj.accumulateSignNumber ? 'lessThan' : '',
                  ]"
                  v-show="item.id != 1"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="repairBox">
          <div class="addUpDayBox">
            <div class="addUp">可补签次数：</div>
            <div class="Day">
              <div>
                {{ signInObj.monthlyFreeSignNumber + signInObj.giveSignNumber }}
              </div>
              <div class="Daysp1">
                （每月一次，购买月卡及以上套餐可额外获得一次）
              </div>
            </div>
          </div>
          <div class="repairGrid">
            <div
              :class="weekfFewStatus(item.whatDay).whetherClass"
              v-for="item in repairTotalList"
              :key="item.id"
              @click="
                repairSignInBut(
                  item,
                  weekfFewStatus(item.whatDay).whetherSignIn
                )
              "
            >
              <div>{{ weekfFewStatus(item.whatDay).name }}</div>
              <img
                v-show="weekfFewStatus(item.whatDay).whetherSignIn == 0"
                class="repairGridImg"
                src="../../assets/img/homeAmend/signIn/notYetIcon.png"
                alt=""
              />
              <img
                v-show="weekfFewStatus(item.whatDay).whetherSignIn == 1"
                class="repairGridImg"
                src="../../assets/img/homeAmend/signIn/alreadyIcon.png"
                alt=""
              />
              <img
                v-show="weekfFewStatus(item.whatDay).whetherSignIn == 2"
                class="repairGridImg"
                src="../../assets/img/homeAmend/signIn/mendIcon.png"
                alt=""
              />
              <div>{{ weekfFewStatus(item.whatDay).time }}</div>
            </div>
            <!-- <div class="repairNone">
              <div>{{ weekfFewStatus("monday").name }}</div>
              <div>{{ weekfFewStatus("monday").time }}</div>
            </div> -->
          </div>
        </div>
      </div>
      <div
        v-throttle="2000"
        @click="signInBut(todayWhetherSignIn())"
        :class="todayWhetherSignIn() != 1 ? 'signInBut' : 'signInButAlready'"
      >
        {{ todayWhetherSignIn() != 1 ? "签到" : "今日已签到" }}
        <div class="hintbott" v-if="todayWhetherSignIn() == 1">
          已获得1小时云手机时长
        </div>
      </div>
    </van-popup>
    <van-dialog
      v-model="detainmentShow"
      :showConfirmButton="false"
      className="detainment"
    >
      <div class="detainmentBox">
        <img
          @click="detainmentShow = false"
          class="dialogClose"
          src="../../assets/img/homeAmend/timeIimitrenew/dialogClose.png"
          alt=""
        />
        <img
          class="dialogHeadImg"
          src="../../assets/img/homeAmend/signIn/dialogImg.png"
          alt=""
        />
        <div class="dialogTitle">签到成功!</div>
        <div class="dialogHint1">已获得1小时签到奖励</div>
        <div class="dialogHint2">
          奖励时长将自动发放到您的剩余时间最长的<br />云手机中
        </div>
        <div class="dialogButtonBox">
          <van-button
            @click="detainmentShow = false"
            class="dialogGoOn"
            type="info"
            >确认</van-button
          >
        </div>
      </div>
    </van-dialog>
  </div>
</template>
  
  <script>
import { signInlist, userSign } from "@/api/index.js";
import { Toast } from "vant";
export default {
  name: "SignInPopup",
  props: {
    signInShow: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      show: false,
      weekTotalList: [
        {
          id: 1,
          name: "第1天",
          img: require("../../assets/img/homeAmend/signIn/redPacket1.png"),
        },
        {
          id: 2,
          name: "第2天",
          img: require("../../assets/img/homeAmend/signIn/redPacket1.png"),
        },
        {
          id: 3,
          name: "+6小时",
          img: require("../../assets/img/homeAmend/signIn/redPacket1.png"),
        },
        {
          id: 4,
          name: "第4天",
          img: require("../../assets/img/homeAmend/signIn/redPacket1.png"),
        },
        {
          id: 5,
          name: "9折续费券",
          img: require("../../assets/img/homeAmend/signIn/redPacket1.png"),
        },
        {
          id: 6,
          name: "第6天",
          img: require("../../assets/img/homeAmend/signIn/redPacket1.png"),
        },
        {
          id: 7,
          name: "+12小时",
          img: require("../../assets/img/homeAmend/signIn/redPacket2.png"),
        },
      ],
      repairTotalList: [
        {
          id: 1,
          whatDay: "monday",
        },
        {
          id: 2,
          whatDay: "tuesday",
        },
        {
          id: 3,
          whatDay: "wednesday",
        },
        {
          id: 4,
          whatDay: "thursday",
        },
        {
          id: 5,
          whatDay: "friday",
        },
        {
          id: 6,
          whatDay: "saturday",
        },
        {
          id: 7,
          whatDay: "sunday",
        },
      ],
      whetherSignIn: true,
      detainmentShow: false,
      signInObj: {},
      atPresentWhatList: [
        {
          id: 1,
          name: "星期一",
          whatDay: "monday",
        },
        {
          id: 2,
          name: "星期二",
          whatDay: "tuesday",
        },
        {
          id: 3,
          name: "星期三",
          whatDay: "wednesday",
        },
        {
          id: 4,
          name: "星期四",
          whatDay: "thursday",
        },
        {
          id: 5,
          name: "星期五",
          whatDay: "friday",
        },
        {
          id: 6,
          name: "星期六",
          whatDay: "saturday",
        },
        {
          id: 7,
          name: "星期日",
          whatDay: "sunday",
        },
      ],
      customData: [
        {
          id: 1,
          name: "周一",
          whatDay: "monday",
          time: "1小时",
        },
        {
          id: 2,
          name: "周二",
          whatDay: "tuesday",
          time: "1小时",
        },
        {
          id: 3,
          name: "周三",
          whatDay: "wednesday",
          time: "1小时",
        },
        {
          id: 4,
          name: "周四",
          whatDay: "thursday",
          time: "1小时",
        },
        {
          id: 5,
          name: "周五",
          whatDay: "friday",
          time: "1小时",
        },
        {
          id: 6,
          name: "周六",
          whatDay: "saturday",
          time: "1小时",
        },
        {
          id: 7,
          name: "周日",
          whatDay: "sunday",
          time: "1小时",
        },
      ],
    };
  },
  watch: {
    signInShow(newV, oldV) {
      if (newV) {
        this.show = newV;
      }
    },
  },

  mounted() {
    this.getSignInlist();
  },
  computed: {
    // 计算列表展示对象
    weekfFewStatus: {
      get() {
        return function (weekfFew) {
          let customDataFind = this.customData.find(
            (item) => item.whatDay == weekfFew
          );

          let atPresentWha = this.atPresentWhatList.find(
            (item) => item.name == this.signInObj.dayOfWeek
          );
          if (this.signInObj.dayOfWeek) {
            customDataFind.whetherClass = "repairNone";
            //判断已经接口已经返回参数
            // 0未签到，1已签到。2补签
            // 列表星期几大于当日星期几未签到
            if (customDataFind.id > atPresentWha.id) {
              customDataFind.whetherSignIn = 0;
            } else if (customDataFind.id == atPresentWha.id) {
              if (this.signInObj[weekfFew] == 1) {
                customDataFind.whetherSignIn = 1;
                customDataFind.whetherClass = "repairAlready";
              } else {
                customDataFind.whetherSignIn = 0;
              }
            } else {
              //已签到或补签
              if (this.signInObj[weekfFew] == 1) {
                customDataFind.whetherSignIn = 1;
                customDataFind.whetherClass = "repairAlready";
              } else {
                customDataFind.whetherSignIn = 2;
              }
            }
          }
          return customDataFind;
        };
      },
    },
    // 今日是否签到
    todayWhetherSignIn: {
      get() {
        return function () {
          let todaySignInShow = null;
          if (this.signInObj.dayOfWeek) {
            let _atPresentWhatList = this.atPresentWhatList.find((item) => {
              return item.name == this.signInObj.dayOfWeek;
            });
            todaySignInShow = this.signInObj[_atPresentWhatList.whatDay];
          }
          return todaySignInShow;
        };
      },
    },
  },
  methods: {
    closePopup() {
      this.$emit("closeChildDialog", false);
    },
    // 签到
    signInBut(Show) {
      if (Show == 1) {
        return;
      }
      if (!this.whetherSignIn) {
        return;
      }
      this.whetherSignIn = false;
      let _atPresentWhatList = this.atPresentWhatList.find((item) => {
        return item.name == this.signInObj.dayOfWeek;
      });
      let data = {
        ...this.signInObj,
        isDoubling: 0,
      };
      data[_atPresentWhatList.whatDay] = 1;
      userSign(data).then((res) => {
        if (res.code == 200) {
          this.getSignInlist();
          this.show = false;
          this.detainmentShow = true;
        } else {
          Toast(res.msg);
        }
        this.whetherSignIn = true;
      });
    },
    // 补签
    repairSignInBut(item, whetherSignIn) {
      if (whetherSignIn == 2) {
        if (
          this.signInObj.monthlyFreeSignNumber + this.signInObj.giveSignNumber >
          0
        ) {
          let data = {
            ...this.signInObj,
            isDoubling: 0,
          };
          data[item.whatDay] = 1;
          this.signInObj.monthlyFreeSignNumber > 0
            ? (data.monthlyFreeSignNumber -= 1)
            : (data.giveSignNumber -= 1);
          userSign(data).then((res) => {
            if (res.code == 200) {
              this.getSignInlist();
              this.show = false;
              this.detainmentShow = true;
            } else {
              Toast(res.msg);
            }
          });
        } else {
          Toast("补签失败，补签次数不足");
        }
      }
    },
    getSignInlist() {
      signInlist().then((res) => {
        this.signInObj = res.data;
      });
    },
  },
};
</script>
  
  <style lang="less" scoped>
.setting_tc {
  background: linear-gradient(360deg, #f5f4f9 0%, #ffefef 100%);
  border-radius: 20px 20px 0px 0px;
  font-family: MiSans, MiSans;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.homeBox {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 18px;
  height: 100px;
  .nameImg {
    width: 128px;
    height: 58px;
  }
  .homeRight {
    .headImg {
      width: 86px;
      height: 80px;
    }
    .addUpBox {
      width: 119px;
      height: 24px;
      background: linear-gradient(90deg, #feffe5 0%, #ffdeb8 100%);
      border-radius: 20px 20px 20px 20px;
      border: 1px solid #ffe2c0;
      font-weight: 600;
      font-size: 12px;
      color: #b88434;
      line-height: 24px;
      margin-top: -28px;
      position: relative;
      z-index: 1;
    }
  }
}
.content {
  box-sizing: border-box;
  padding: 0 16px;
  .scheduleBox {
    width: 100%;
    height: 159px;
    background: linear-gradient(360deg, #fffdf9 0%, #ffefe2 100%);
    border-radius: 14px 14px 14px 14px;
    border: 1px solid #ffffff;
    .addUpDayBox {
      display: flex;
      font-weight: 500;
      font-size: 14px;
      color: #505465;
      line-height: 16px;
      margin: 20px 0 8px 18px;
      .Day {
        color: #f96d6f;
      }
    }
    .scheduleContent {
      display: flex;
      justify-content: space-evenly;
      .scheduleList {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        .scheduleListTop {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .specialextra {
          visibility: hidden;
        }
        .extra {
          width: 24px;
          height: 12px;
          background: #f96d6f;
          border-radius: 12px;
          font-weight: 500;
          font-size: 8px;
          color: #ffffff;
          line-height: 12px;
          position: relative;
          margin-bottom: 5px;
          &::after {
            position: absolute;
            top: 100%;
            left: 50%;
            content: "";
            width: 0;
            height: 0;
            transform: translateX(-50%);
            // 因为我需要的是倒三角,所以只需要给 border-top加颜色即可,其他的就让它透明,以此类推
            border-top: 3px solid #f96d6f;
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
            border-bottom: 3px solid transparent;
          }
        }
        .redPacketImg {
          width: 36px;
          height: 36px;
        }
        .dayName {
          font-weight: 500;
          font-size: 8px;
          color: #505465;
          margin-top: 3px;
          // line-height: 16px;
        }
        .specialName {
          width: 45px;
          height: 16px;
          background: rgba(255, 153, 153, 0.2);
          border-radius: 10px;
          font-weight: 500;
          font-size: 8px;
          color: #f96d6f;
          line-height: 16px;
          margin-top: 0;
        }
        .progressBar {
          position: relative;
          margin-top: 16px;
          .circle {
            width: 7px;
            height: 7px;
            background: #f96d6f;
            border-radius: 7px;
            position: relative;
            z-index: 1;
          }
          .lessThan {
            background: #a9adb6 !important;
          }
          .line {
            width: 42px;
            height: 2px;
            background: #f96d6f;
            // border-radius: 2px;
            // margin: -4.5px 0 0 7px;
            position: absolute;
            top: 2.5px;
            right: 7px;
          }
        }
      }
    }
  }
  .repairBox {
    width: 100%;
    height: 268px;
    background: #fffefa;
    border-radius: 14px;
    border: 1px solid #ffffff;
    margin-top: 15px;
    .addUpDayBox {
      display: flex;
      font-weight: 500;
      font-size: 14px;
      color: #505465;
      line-height: 16px;
      margin: 20px 0 8px 18px;
      .Day {
        color: #f96d6f;
        display: flex;
      }
      .Daysp1 {
        font-size: 9px;
        margin-top: -1px;
      }
    }
    .repairGrid {
      display: grid;
      grid-template-columns: 66px 66px 66px 66px;
      grid-template-rows: 88px 88px;
      row-gap: 14px;
      column-gap: 14px;
      box-sizing: border-box;
      padding: 0 18px;
      margin-top: 24px;
      .repairAlready {
        background: #f7f7f7;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #f0f0f0;
        font-weight: 600;
        font-size: 12px;
        color: #b8bdcc;
        line-height: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .repairNone {
        background: linear-gradient(180deg, #fffaf1 0%, #fff3e0 100%);
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #ffeacb;
        font-weight: 600;
        font-size: 12px;
        color: #f96d6f;
        line-height: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .repairGridImg {
        width: 28px;
        height: 28px;
        margin: 4px 0;
      }
    }
  }
}
.signInBut {
  width: 300px;
  height: 44px;
  background: linear-gradient(90deg, #4ed5ff 0%, #4d94ff 47%, #1157fa 100%);
  box-shadow: inset -1px -2px 3px 0px #8cffe3, inset 0px 2px 2px 0px #6b83ff,
    0px 5px 11px 0px rgba(41, 111, 248, 0.25);
  border-radius: 44px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  line-height: 44px;
  margin: 30px auto 30px auto;
  position: relative;
}
.signInButAlready {
  width: 300px;
  height: 44px;
  background: linear-gradient(90deg, #d5ddf0 0%, #b2c5db 47%, #bac8eb 100%);
  box-shadow: inset -1px -2px 3px 0px #e7eff2, inset 0px 2px 2px 0px #b4bade,
    0px 5px 11px 0px rgba(41, 111, 248, 0.25);
  border-radius: 44px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  line-height: 44px;
  margin: 30px auto 30px auto;
  position: relative;
}
.hintbott {
  position: absolute;
  width: 121px;
  height: 20px;
  line-height: 20px;
  background: #f96d6f;
  border-radius: 256px 256px 256px 256px;
  top: -30px;
  right: 0;
  font-family: MiSans, MiSans;
  font-weight: 500;
  font-size: 10px;
}
.hintbott::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #f96d6f; /* 颜色可根据需要调整 */
  position: absolute;
  top: 100%; /* 调整箭头的位置 */
  left: 50%;
  transform: translateX(-50%);
}

.detainment {
  width: 300px;
  background: linear-gradient(180deg, #fff0f0 0%, #ffffff 100%);
  border-radius: 16px 16px 16px 16px;
  border: 1px solid #ffffff;
  overflow: visible;
  .detainmentBox {
    width: 100%;
    height: 100%;
    position: relative;
    .dialogClose {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 16px;
      height: 16px;
    }
    .dialogHeadImg {
      width: 100px;
      height: 95px;
      margin-top: -48px;
    }
    .dialogTitle {
      font-weight: 600;
      font-size: 24px;
      color: #505465;
      line-height: 29px;
      margin-top: 19px;
    }
    .dialogHint1 {
      font-weight: 500;
      font-size: 14px;
      color: #596380;
      line-height: 25px;
      margin-top: 8px;
    }
    .dialogHint2 {
      font-weight: 500;
      font-size: 12px;
      color: #596380;
      line-height: 22px;
    }
    .dialogButtonBox {
      margin-top: 24px;
      margin-bottom: 30px;
      .dialogQuit {
        width: 140px;
        height: 44px;
        background: linear-gradient(
          90deg,
          #d5ddf0 0%,
          #b2c5db 47%,
          #bac8eb 100%
        );
        box-shadow: inset -1px -2px 3px 0px #e7eff2,
          inset 0px 2px 2px 0px #b4bade,
          0px 5px 11px 0px rgba(41, 111, 248, 0.25);
        border-radius: 256px 256px 256px 256px;
        color: #ffffff;
        font-family: MiSans, MiSans;
        font-weight: 500;
        font-size: 14px;
        margin-right: 10px;
      }
      .dialogGoOn {
        width: 124px;
        height: 44px;
        background: linear-gradient(
          90deg,
          #4ed5ff 0%,
          #4d94ff 47%,
          #1157fa 100%
        );
        box-shadow: inset -1px -2px 3px 0px #8cffe3,
          inset 0px 2px 2px 0px #6b83ff,
          0px 5px 11px 0px rgba(41, 111, 248, 0.25);
        border-radius: 256px 256px 256px 256px;
        color: #ffffff;
        font-family: MiSans, MiSans;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
}
</style>