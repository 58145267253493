var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Footer"},[_c('div',{staticClass:"baseNav"},[_c('div',{staticClass:"NavList"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"NavImg",attrs:{"src":this.$route.path == '/'
              ? require('../assets/img/homeAmend/phonePitch.png')
              : require('../assets/img/homeAmend/phoneDefault.png'),"alt":""}}),_c('div',{class:['Navname', this.$route.path == '/' ? 'active' : '']},[_vm._v(" 云手机 ")])])],1),_c('div',{staticClass:"NavList"},[_c('router-link',{attrs:{"to":"/discover"}},[_c('img',{staticClass:"NavImg",attrs:{"src":this.$route.path == '/discover'
              ? require('../assets/img/discoverAmend/navIcon_active.png')
              : require('../assets/img/discoverAmend/navIcon.png'),"alt":""}}),_c('div',{class:[
            'Navname',
            this.$route.path == '/discover' ? 'active' : '',
          ]},[_vm._v(" 发现 ")])])],1),_c('div',{staticClass:"NavList"},[_c('router-link',{attrs:{"to":"/mine"}},[_c('img',{staticClass:"NavImg",attrs:{"src":this.$route.path == '/mine'
              ? require('../assets/img/homeAmend/myPitch.png')
              : require('../assets/img/homeAmend/myDefault.png'),"alt":""}}),_c('div',{class:['Navname', this.$route.path == '/mine' ? 'active' : '']},[_vm._v(" 我的 ")])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }