import Vue from 'vue'
import VueRouter from 'vue-router'
import mine from '../../views/mine/mine.vue'
Vue.use(VueRouter)

const MINE = [
    {
        path: '/mine',
        name: 'mine',
        component: mine
    }, {
        path: '/Cloudsetting',
        name: 'CloudPhoneSetup',
        component: () => import('../../views/mine/CloudPhoneSetup.vue')
    }, {
        path: '/setting',
        name: 'Setting',
        component: () => import('../../views/mine/Setting.vue')
    }, {
        path: '/messagecenter',
        name: 'Messagecenter',
        component: () => import('../../views/mine/MessageCenter.vue')
    }, {
        path: '/individual',
        name: 'Individual',
        component: () => import('../../views/mine/Individual.vue')
    }, {
        path: '/changephone',
        name: 'Changephone',
        component: () => import('../../views/mine/Changephone.vue')
    }, {
        path: '/changenickname',
        name: 'ChangeNickname',
        component: () => import('../../views/mine/ChangeNickname.vue')
    }, {
        path: '/changetiephone',
        name: 'Changetiephone',
        component: () => import('../../views/mine/Changetiephone.vue')
    }, {
        path: '/aboutus',
        name: 'Aboutus',
        component: () => import('../../views/mine/Aboutus.vue')
    }, {
        path: '/invitefriends',
        name: 'InviteFriends',
        component: () => import('../../views/mine/InviteFriends.vue')
    }, {
        path: '/conversionduration',
        name: 'ConversionDuration',
        component: () => import('../../views/mine/ConversionDuration.vue')
    }, 
    {
        path: '/invitelandingpage',
        name: 'Invitelandingpage',
        component: () => import('../../views/mine/Invitelandingpage.vue')
    },
    {
        path: '/authorizationmanagement',
        name: 'Authorizationmanagement',
        component: () => import('../../views/mine/Accredit/Authorizationmanagement.vue')
    }, {
        path: '/equipmentauthorization',
        name: 'Equipmentauthorization',
        component: () => import('../../views/mine/Accredit/Equipmentauthorization.vue')
    }, {
        path: '/homepagelicensing',
        name: 'Homepagelicensing',
        component: () => import('../../views/mine/Accredit/Homepagelicensing.vue')
    }, {
        path: '/authorizationrecord',
        name: 'Authorizationrecord',
        component: () => import('../../views/mine/Accredit/Authorizationrecord.vue')
    }, {
        path: '/shareBrokerage',
        name: 'ShareBrokerage',
        component: () => import('../../views/mine/shareBrokerage/index.vue')
    },
    {
        path: '/withdrawDeposit',
        name: 'WithdrawDeposit',
        component: () => import('../../views/mine/shareBrokerage/withdrawDeposit.vue')
    },
    {
        path: '/coupon',
        name: 'Coupon',
        component: () => import('../../views/mine/coupon/index.vue')
    },
    {
        path: '/available',
        name: 'Available',
        component: () => import('../../views/mine/coupon/available.vue')
    },
    {
        path: '/clientServe',
        name: 'ClientServe',
        component: () => import('../../views/mine/clientServe.vue')
    },
    {
        path: '/switchAccount',
        name: 'SwitchAccount',
        component: () => import('../../views/mine/switchAccount')
    },
    {
        path: '/cutLogin',
        name: 'CutLogin',
        component: () => import('../../views/mine/switchAccount/cutLogin.vue')
    },
    {
        path: '/agencyCentre',
        name: 'AgencyCentre',
        component: () => import('../../views/mine/agencyCentre/index.vue')
    },
    {
        path: '/needToKnow',
        name: 'NeedToKnow',
        component: () => import('../../views/mine/agencyCentre/needToKnow.vue')
    },
    {
        path: '/handBook',
        name: 'HandBook',
        component: () => import('../../views/mine/agencyCentre/handBook.vue')
    },
    {
        path: '/promotionRule',
        name: 'PromotionRule',
        component: () => import('../../views/mine/agencyCentre/promotionRule.vue')
    },
    {
        path: '/promotionCentre',
        name: 'PromotionCentre',
        component: () => import('../../views/mine/agencyCentre/promotionCentre.vue')
    },
    {
        path: '/registerRecord',
        name: 'RegisterRecord',
        component: () => import('../../views/mine/agencyCentre/registerRecord.vue')
    },
]

export default MINE
