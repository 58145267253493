
<template>
  <div class="nine_boxgrid">
    <van-swipe
      @change="swipe_indicator"
      class="my-swipe"
      :loop="false"
      indicator-color="white"
      :stop-propagation="false"
      ref="nine_swipe"
    >
      <van-swipe-item v-for="(items, indexs) in newInstanceList" :key="indexs">
        <div class="div_img" v-for="(item, index) in items" :key="index">
          <div class="ysj_name">
            <img
              v-if="(item.vipCode == 'VIP1') & (item.deviceVersionCode == 'V8')"
              src="../../assets/icons/biaozhunanzhuo8.png"
              alt=""
            />
            <img
              v-if="
                (item.vipCode == 'VIP1') & (item.deviceVersionCode == 'V10')
              "
              src="../../assets/icons/biaozhunanzhuo10.png"
              alt=""
            />
            <img
              v-if="(item.vipCode == 'VIP2') & (item.deviceVersionCode == 'V8')"
              src="../../assets/icons/changwananzhuo8.png"
              alt=""
            />
            <img
              v-if="
                (item.vipCode == 'VIP2') & (item.deviceVersionCode == 'V10')
              "
              src="../../assets/icons/changwananzhuo10.png"
              alt=""
            />
            <img
              v-if="(item.vipCode == 'VIP3') & (item.deviceVersionCode == 'V8')"
              src="../../assets/icons/aorananzhuo8.png"
              alt=""
            />
            <img
              v-if="
                (item.vipCode == 'VIP3') & (item.deviceVersionCode == 'V10')
              "
              src="../../assets/icons/aorananzhuo10.png"
              alt=""
            />
            <img
              v-if="(item.vipCode == 'VIP4') & (item.deviceVersionCode == 'V8')"
              src="../../assets/icons/zhizunanzhuo8.png"
              alt=""
            />
            <img
              v-if="
                (item.vipCode == 'VIP4') & (item.deviceVersionCode == 'V10')
              "
              src="../../assets/icons/zhizunanzhuo10.png"
              alt=""
            />
            <img
              v-if="
                (item.vipCode != 'VIP1') &
                (item.vipCode != 'VIP2') &
                (item.vipCode != 'VIP3') &
                (item.vipCode != 'VIP4')
              "
              src="../../assets/icons/dingzhianzhuo.png"
              alt=""
            />
            <span>{{ item.instanceName }}</span>
            <span class="yishouquan" v-if="item.isAuthorized == 2">已授权</span>
            <span class="beishouquan" v-if="item.isAuthorized == 1"
              >被授权</span
            >
            <p></p>
            <img
              @click="popupshow(item)"
              class="seting"
              src="../../assets/icons/settingm_g.png"
              alt=""
            />
          </div>
          <div v-if="item.maintenanceStatus == 1" class="weihu">
            <div>
              <p>本云手机正在维护中</p>
              <p>预计维护时间为：</p>
              {{ item.maintenanceText }}
              <p>维护时间结束后请点击刷新按钮</p>
              建议维护完成 后重启云手机
              <p>给您带来不便敬请谅解</p>
            </div>
          </div>
          <div v-else @click="BgsSdk_child(item)" class="feiweihu">
            <img
              class="imgs"
              v-if="shotinfoList && shotinfoList.length != 0"
              :src="imageurl(item)"
              alt=""
            />
            <img
              v-else
              class="imgs"
              src="../../assets/img/moren_ysj.png"
              alt=""
            />
          </div>
          <div class="shengyu_time">剩余时间：{{ item.expireTime }}</div>
        </div>
        <div
          v-show="indexs + 1 >= newInstanceList.length"
          class="div_img lijigoumai"
          @click="lijigoumai"
        >
          <button class="ljgm_btn">立即购买</button>
        </div>
        <p class="yeshu">{{ indexs + 1 }}/{{ newInstanceList.length }}</p>
      </van-swipe-item>
    </van-swipe>
    <Aochenpeizhi :Aochen_phone="aochenphone" ref="aochen_peizhis" />
  </div>
</template>

<script>
import { commandscreenshot, commandscreenshotinfo } from "@/api/index.js";
import { Toast } from "vant";
import Aochenpeizhi from "@/components/cell/Aochen_peizhi.vue";
export default {
  name: "Aochenyunshoujinineboxgrid",
  components: {
    Aochenpeizhi,
  },
  props: {
    InstanceList: {
      default: null,
      type: Array,
    },
    gonggeshow: {
      default: null,
      type: Number,
    },
  },

  data() {
    return {
      active: 0,
      screenshotList: [],
      shotinfoList: null,
      screen_Interval: null,
      screen_Timeout: null,
      swiper_stop: true,
      userdata: null,
      aochenphone: null,
      newInstanceList: [],
    };
  },

  mounted() {
    let that = this;
    that.userdata = JSON.parse(localStorage.getItem("data"));
    if (that.userdata) {
      that.screen_Interval = setInterval(function () {
        that.getscreenshot();
      }, 5000);
    }
    if (this.InstanceList) {
      this.newInstanceList = [];
      for (let i = 0; i < this.InstanceList.length; i += 9) {
        this.newInstanceList.push(this.InstanceList.slice(i, i + 9));
      }
    }
  },

  methods: {
    imageurl(em) {
      if (em) {
        for (let i = 0; i < this.shotinfoList.length; i++) {
          if (em.instanceCode == this.shotinfoList[i].instanceCode) {
            return this.shotinfoList[i].url;
          }
        }
      }
    },
    // 获取当前轮播页
    swipe_indicator(e) {
      let that = this;
      that.active = e;
      clearInterval(that.screen_Interval);
      that.screen_Interval = null;
      if (that.screen_Interval == null) {
        that.screen_Interval = setInterval(function () {
          that.getscreenshot();
        }, 3000);
      }
    },
    // 启动云手机
    BgsSdk_child(em) {
      clearInterval(this.screen_Interval);
      this.screen_Interval = null;
      this.swiper_stop = false;
      this.$parent.BgsSdca(em);
    },
    popupshow(em) {
      this.aochenphone = em;
      this.$refs.aochen_peizhis.popupshows();
    },
    // 立即购买
    lijigoumai() {
      this.userdata = JSON.parse(localStorage.getItem("data"));
      if (this.userdata) {
        this.$router.push("/AddCloudPhone");
      } else {
        this.$router.push("/login");
      }
    },

    // 获取设备截图
    async getscreenshot() {
      let that = this;
      let a = that.newInstanceList[that.active];
      let instanceCodes = [];
      for (let i = 0; i < a.length; i++) {
        if (a[i].maintenanceStatus != 1) {
          instanceCodes.push(a[i].instanceCode);
        }
      }
      let data = {
        instanceCodes: instanceCodes,
        quality: 3,
      };
      await commandscreenshot(data).then((res) => {
        if (res.code == 200) {
          that.screenshotList = res.data;
          that.screen_Timeout = setTimeout(function () {
            that.getscreenshotinfo();
          }, 1000);
        } else {
          Toast(res.msg);
        }
      });
    },
    // 获取截图结果
    async getscreenshotinfo() {
      let that = this;
      let taskIds = [];
      for (let i = 0; i < that.screenshotList.length; i++) {
        taskIds.push(that.screenshotList[i].taskId);
      }
      let data = {
        taskIds: taskIds,
      };
      await commandscreenshotinfo(data).then((res) => {
        if (res.code == 200) {
          that.shotinfoList = res.data;
        } else {
          Toast(res.msg);
        }
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.screen_Interval);
    this.screen_Interval = null;
  },
};
</script>

<style lang="less" scoped>
.nine_boxgrid {
  .div_img {
    position: relative;
    width: calc(33% - 10px);
    height: 192px;
    overflow: hidden;
    border-radius: 10px;
    margin: 0 10px 10px 0;
    .ysj_name {
      position: absolute;
      top: 0;
      width: 100%;
      height: 20px;
      background: rgba(30, 33, 51, 0.6);
      border-radius: 12px 12px 0px 0px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: left;
      font-size: 6px;
      z-index: 1;
      img {
        width: 10px;
        height: 10px;
        margin: 0 5px;
      }
      span {
        flex: 1;
        text-align: left;
        font-size: 10px;
      }
      .seting {
        width: 16px;
        height: 16px;
      }
      .yishouquan {
        width: 13px;
        height: 5px;
        background: #3ac984;
        border-radius: 1px 3px 3px 3px;
        font-size: 3px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 5px;
        margin-left: 1px;
      }
      .beishouquan {
        width: 13px;
        height: 5px;
        background: #ff2828;
        border-radius: 1px 3px 3px 3px;
        font-size: 3px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 5px;
        margin-left: 1px;
      }
      p {
        flex: 1;
      }
    }
    .shengyu_time {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 20px;
      background: rgba(30, 33, 51, 0.6);
      border-radius: 0px 0px 12px 12px;
      color: #fff;
      text-align: center;
      line-height: 20px;
      font-size: 6px;
    }
    .imgs {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 10px;
    }
    .weihu {
      margin: 0 12px;
      width: calc(100% - 24px);
      height: 100%;
      background-image: url("../../assets/img/zhengzaiweihu.png");
      background-size: 100% 100%;
      position: relative;
      div {
        position: absolute;
        bottom: 28px;
      }
      p {
        margin: 4px 0;
        color: rgb(74, 74, 74);
        font-family: 思源黑体;
        font-size: 6px;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: center;
      }
    }
    .feiweihu {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .custom_indicator {
    margin-top: 12px;
  }
  .lijigoumai {
    background-image: url("../../assets/img/home.png");
    background-size: 100% 100%;
  }
  .ljgm_btn {
    position: absolute;
    bottom: 50px;
    left: 0;
    border: none;
    background: none;
    margin: 0 10%;
    color: #fff;
    width: 80%;
    height: 20px;
    font-size: 10px;
    background: #558bff;
    border-radius: 22px;
  }
  /deep/ .van-tabs__wrap {
    display: none;
  }
  .van-swipe {
    margin: 10px 0 10px 10px;
    width: calc(100% - 10px);
  }
  /deep/ .van-swipe-item {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-wrap: wrap;
    width: 100%;
    .yeshu {
      width: 100%;
      text-align: center;
    }
  }
}
</style>