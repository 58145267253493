import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const server = [
    {
        path: '/grouping',
        name: 'Grouping',
        component: () => import('../../views/Home/batchOf/grouping.vue')
    },
    {
        path: '/selectGrop',
        name: 'SelectGrop',
        component: () => import('../../views/Home/batchOf/selectGrop.vue')
    },
    {
        path: '/batchAll',
        name: 'BatchAll',
        component: () => import('../../views/Home/batchOf/batchAll.vue')
    },
    {
        path: '/allGoOnMoney',
        name: 'AllGoOnMoney',
        component: () => import('../../views/Home/batchOf/allGoOnMoney.vue')
    },
]

export default server