import { render, staticRenderFns } from "./Singlecard.vue?vue&type=template&id=625d6510&scoped=true"
import script from "./Singlecard.vue?vue&type=script&lang=js"
export * from "./Singlecard.vue?vue&type=script&lang=js"
import style0 from "./Singlecard.vue?vue&type=style&index=0&id=625d6510&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "625d6510",
  null
  
)

export default component.exports