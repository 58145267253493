import { render, staticRenderFns } from "./BatchPopup.vue?vue&type=template&id=a796a010&scoped=true"
import script from "./BatchPopup.vue?vue&type=script&lang=js"
export * from "./BatchPopup.vue?vue&type=script&lang=js"
import style0 from "./BatchPopup.vue?vue&type=style&index=0&id=a796a010&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a796a010",
  null
  
)

export default component.exports