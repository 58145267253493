import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './modules/user'
import common from './modules/common'
import persistence from './modules/persistence'
import channelPreloaded from './modules/channelPreloaded'
import createPersistedstate  from 'vuex-persistedstate'
// 应用vuex插件
Vue.use(Vuex)

// 创建并暴露store
export default new Vuex.Store({
    // 数据,相当于data
    state: {
        // sum: 0,
    },
    //准备mutations——用于操作数据（state）
    mutations: {
        // reduce(state, value) {
        //     state.sum -= value
        // }
    },
    //准备actions——用于响应组件中的动作
    actions: {

    },
    //准备getters——用于将state中的数据进行加工
    getters,
    // 模块化
    modules: {
        user,
        common,
        persistence,
        channelPreloaded
    },
    plugins: [
        createPersistedstate({
            key: 'popDialog',// 存数据的key名   自定义的  要有语义化
            paths: ['persistence'], // 要把那些模块加入缓存
            storage: window.sessionStorage
        })
    ]

})