import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const server = [
    {
        path: '/issuingplatform',
        name: 'Issuingplatform',
        component: () => import('../../views/Issuingplatform/Issuingplatform.vue')
    }, {
        path: '/activationcode',
        name: 'Activationcode',
        component: () => import('../../views/Issuingplatform/Activationcode.vue')
    }, {
        path: '/activationresult',
        name: 'Activationresult',
        component: () => import('../../views/Issuingplatform/Activationresult.vue')
    }, {
        path: '/historyKey',
        name: 'HistoryKey',
        component: () => import('../../views/Issuingplatform/HistoryKey.vue')
    }, {
        path: '/keyParticulars',
        name: 'KeyParticulars',
        component: () => import('../../views/Issuingplatform/KeyParticulars.vue')
    }

]

export default server
