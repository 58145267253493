var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nine_boxgrid"},[_c('van-swipe',{ref:"nine_swipe",staticClass:"my-swipe",attrs:{"loop":false,"indicator-color":"white","stop-propagation":false},on:{"change":_vm.swipe_indicator}},_vm._l((_vm.newInstanceList),function(items,indexs){return _c('van-swipe-item',{key:indexs},[_vm._l((items),function(item,index){return _c('div',{key:index,staticClass:"div_img"},[_c('div',{staticClass:"ysj_name"},[((item.vipCode == 'VIP1') & (item.deviceVersionCode == 'V8'))?_c('img',{attrs:{"src":require("../../assets/icons/biaozhunanzhuo8.png"),"alt":""}}):_vm._e(),(
              (item.vipCode == 'VIP1') & (item.deviceVersionCode == 'V10')
            )?_c('img',{attrs:{"src":require("../../assets/icons/biaozhunanzhuo10.png"),"alt":""}}):_vm._e(),((item.vipCode == 'VIP2') & (item.deviceVersionCode == 'V8'))?_c('img',{attrs:{"src":require("../../assets/icons/changwananzhuo8.png"),"alt":""}}):_vm._e(),(
              (item.vipCode == 'VIP2') & (item.deviceVersionCode == 'V10')
            )?_c('img',{attrs:{"src":require("../../assets/icons/changwananzhuo10.png"),"alt":""}}):_vm._e(),((item.vipCode == 'VIP3') & (item.deviceVersionCode == 'V8'))?_c('img',{attrs:{"src":require("../../assets/icons/aorananzhuo8.png"),"alt":""}}):_vm._e(),(
              (item.vipCode == 'VIP3') & (item.deviceVersionCode == 'V10')
            )?_c('img',{attrs:{"src":require("../../assets/icons/aorananzhuo10.png"),"alt":""}}):_vm._e(),((item.vipCode == 'VIP4') & (item.deviceVersionCode == 'V8'))?_c('img',{attrs:{"src":require("../../assets/icons/zhizunanzhuo8.png"),"alt":""}}):_vm._e(),(
              (item.vipCode == 'VIP4') & (item.deviceVersionCode == 'V10')
            )?_c('img',{attrs:{"src":require("../../assets/icons/zhizunanzhuo10.png"),"alt":""}}):_vm._e(),(
              (item.vipCode != 'VIP1') &
              (item.vipCode != 'VIP2') &
              (item.vipCode != 'VIP3') &
              (item.vipCode != 'VIP4')
            )?_c('img',{attrs:{"src":require("../../assets/icons/dingzhianzhuo.png"),"alt":""}}):_vm._e(),_c('span',[_vm._v(_vm._s(item.instanceName))]),(item.isAuthorized == 2)?_c('span',{staticClass:"yishouquan"},[_vm._v("已授权")]):_vm._e(),(item.isAuthorized == 1)?_c('span',{staticClass:"beishouquan"},[_vm._v("被授权")]):_vm._e(),_c('p'),_c('img',{staticClass:"seting",attrs:{"src":require("../../assets/icons/settingm_g.png"),"alt":""},on:{"click":function($event){return _vm.popupshow(item)}}})]),(item.maintenanceStatus == 1)?_c('div',{staticClass:"weihu"},[_c('div',[_c('p',[_vm._v("本云手机正在维护中")]),_c('p',[_vm._v("预计维护时间为：")]),_vm._v(" "+_vm._s(item.maintenanceText)+" "),_c('p',[_vm._v("维护时间结束后请点击刷新按钮")]),_vm._v(" 建议维护完成 后重启云手机 "),_c('p',[_vm._v("给您带来不便敬请谅解")])])]):_c('div',{staticClass:"feiweihu",on:{"click":function($event){return _vm.BgsSdk_child(item)}}},[(_vm.shotinfoList && _vm.shotinfoList.length != 0)?_c('img',{staticClass:"imgs",attrs:{"src":_vm.imageurl(item),"alt":""}}):_c('img',{staticClass:"imgs",attrs:{"src":require("../../assets/img/moren_ysj.png"),"alt":""}})]),_c('div',{staticClass:"shengyu_time"},[_vm._v("剩余时间："+_vm._s(item.expireTime))])])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(indexs + 1 >= _vm.newInstanceList.length),expression:"indexs + 1 >= newInstanceList.length"}],staticClass:"div_img lijigoumai",on:{"click":_vm.lijigoumai}},[_c('button',{staticClass:"ljgm_btn"},[_vm._v("立即购买")])]),_c('p',{staticClass:"yeshu"},[_vm._v(_vm._s(indexs + 1)+"/"+_vm._s(_vm.newInstanceList.length))])],2)}),1),_c('Aochenpeizhi',{ref:"aochen_peizhis",attrs:{"Aochen_phone":_vm.aochenphone}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }