<template>
  <div class="toAppStore">
    <van-dialog
      v-model="show"
      :showConfirmButton="false"
      width="320px"
      className="deficiency"
    >
      <div v-if="isTitle" class="title">推荐使用傲晨云手机APP</div>
      <div class="TitleBox">
        前往app
        store傲晨云手机下载页面->点击下载->下载完成->打开傲晨云手机app搜索傲晨->进入傲晨云手机
      </div>
      <div class="deleteOperate">
        <button class="deleteCancel" @click="show = false">取消</button>
        <button class="share" @click="toClick">前往</button>
      </div>
    </van-dialog>
  </div>
</template>
  
  <script>
import { Toast } from "vant";
export default {
  name: "toAppStore",
  components: {},
  props: {
    // toShow: {
    //   default: false,
    //   type: Boolean,
    // },
    isTitle: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  watch: {
    // toShow(newV, oldV) {
    //   console.log(newV);
    //   if (newV) {
    //     this.show = newV;
    //   }
    // },
  },

  mounted() {},

  methods: {
    toClick() {
      // window.location.href = src + " scheme://";
      // 跳转appstore埋点
      // if (this.popularize == 1) {
      //   reportPage({
      //     pageName: "试玩下载ios",
      //     pageCode: "attempt_download_ios",
      //     pageParam: "h5/推广",
      //   }).then((res) => {});
      // } else {
      //   reportPage({
      //     pageName: "试玩下载ios",
      //     pageCode: "attempt_download_ios",
      //     pageParam: "h5/正常",
      //   }).then((res) => {});
      // }
      this.show = false;
      location.href =
        // "https://apps.apple.com/cn/app/%E5%82%B2%E6%99%A8%E6%B5%8F%E8%A7%88%E5%99%A8/id6477391368";
        "https://apps.apple.com/cn/app/%E5%82%B2%E6%99%A8%E4%BA%91%E6%89%8B%E6%9C%BA/id6608981742";
    },
    ShowClick() {
      this.show = true;
    },
  },
};
</script>
  
  <style lang="less" scoped>
.title {
  margin-top: 20px;
  margin-bottom: -10px;
  font-size: 16px;
  color: #111;
  font-weight: bold;
}
.deficiency {
  // height: 230px;
  background-image: url("@/assets/img/activity/attemptPlay/popupBack.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 10px;
  .TitleBox {
    // font-weight: bold;
    font-size: 15px;
    color: #111;
    line-height: 24px;
    margin: 25px 15px 26px 15px;
  }
  .deleteOperate {
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 12px;
    .share {
      width: 90px;
      height: 40px;
      background: linear-gradient(135deg, #88ce14 0%, #4fc30a 100%);
      box-shadow: 0px 4px 12px 0px rgba(2, 82, 0, 0.16);
      border-radius: 8px;
      border: 2px solid #eaffe7;
    }
    .deleteCancel {
      width: 90px;
      height: 40px;
      background: linear-gradient(135deg, #888887 0%, #444443 100%);
      box-shadow: 0px 4px 12px 0px rgba(2, 82, 0, 0.16);
      border-radius: 8px;
      border: 2px solid #eaffe7;
    }
    .deleteDownload {
      width: 90px;
      height: 40px;
      background: linear-gradient(135deg, #febe69 0%, #f8a419 100%);
      box-shadow: 0px 4px 12px 0px rgba(2, 82, 0, 0.16);
      border-radius: 8px;
      border: 2px solid #eaffe7;
    }
  }
}
</style>
  