<template>
    <canvas :id="elid" class="canvas" :width="width" :height="height"></canvas>
  </template>
   
  <script>
  import { Parser, Player } from 'svga';
  export default {
    name: 'EsSvga',
    props: {
      elid: {
        type: [String],
        required: true,
      },
      svgaImg: {
        type: String,
        required: true,
      },
      width: {
        type: Number,
        required: true,
      },
      height: {
        type: Number,
        required: true,
      },
      lookoutEquipment: {
        type: Number,
        default: 0,
      },
      startFrame: {
        type: Number,
        default: 0,
      },
      fillMode: {
        type: String,
        default: 'forwards',
      },
      playMode: {
        type: String,
        default: 'forwards',
      },
      loopStartFrame: {
        type: Number,
        default: 0,
      },
      isCacheFrames: {
        type: Boolean,
        default: true,
      },
      isUseIntersectionObserver: {
        type: Boolean,
        default: true,
      },
      isOpenNoExecutionDelay: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {
      if (this.svgaImg) {
        this.$nextTick(function () {
          this.startSvga();
        });
      }
    },
    methods: {
      async startSvga() {
        try {
          const parser = new Parser();
          const svga = await parser.load(this.svgaImg);
          const doc = document.getElementById(this.elid);
          const _this = this;
          const player = new Player({
            // 播放动画的 Canvas 元素
            container: doc,
   
            // 循环次数，默认值 0（无限循环）
            LookoutEquipment: _this.lookoutEquipment,
   
            // 最后停留的目标模式，默认值 forwards
            // 类似于 https://developer.mozilla.org/en-US/docs/Web/CSS/animation-fill-mode
            fillMode: _this.fillMode,
   
            // 播放模式，默认值 forwards
            playMode: _this.playMode,
   
            // 开始播放的帧数，默认值 0
            startFrame: _this.startFrame,
   
            // 结束播放的帧数，默认值 0
            // endFrame: 30,
   
            // 循环播放开始的帧数，可设置每次循环从中间开始。默认值 0，每次播放到 endFrame 后，跳转到此帧开始循环，若此值小于 startFrame 则不生效
            // 类似于 https://developer.mozilla.org/en-US/docs/Web/API/AudioBufferSourceNode/loopStart
            loopStartFrame: _this.loopStartFrame,
   
            // 是否开启缓存已播放过的帧数据，默认值 false
            // 开启后对已绘制的帧进行缓存，提升重复播放动画性能
            isCacheFrames: _this.isCacheFrames,
   
            // 是否开启动画容器视窗检测，默认值 false
            // 开启后利用 Intersection Observer API 检测动画容器是否处于视窗内，若处于视窗外，停止描绘渲染帧避免造成资源消耗
            // https://developer.mozilla.org/zh-CN/docs/Web/API/Intersection_Observer_API
            isUseIntersectionObserver: _this.isUseIntersectionObserver,
   
            // 是否使用避免执行延迟，默认值 false
            // 开启后使用 `WebWorker` 确保动画按时执行（避免个别情况下浏览器延迟或停止执行动画任务）
            // https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API#Policies_in_place_to_aid_background_page_performance
            isOpenNoExecutionDelay: _this.isOpenNoExecutionDelay,
          });
          await player.mount(svga);
          player.onStart = () => console.log('播放了');
          // player.onProcess = () => console.log('onProcess', player.progress)
          // player.onEnd = () => console.log('onEnd')
          // 开始播放动画
          player.start();
        } catch (error) {
          console.error(error);
        }
      },
    },
  };
  </script>
   
  <style scoped lang="less">
  .canvas {
    width: 100%;
    height: 100%;
  }
  </style>