const state = {
    preloadedList: [],
}
const mutations = {
    SET_PRELOADEDLIST: (state, cover) => {
        state.preloadedList = cover
    },
}
export default {
    // 开启命名空间
    namespaced: true,
    mutations,
    state,
}