// 全局变量
// 单独做拼团页面的响应拦截(废弃)
const groupBookingIntercept = false;
let BuiltInLogin=false
// let demoURL='http://192.168.110.6:8080'
// let demoURL='http://uat.aochenyun.cn'
// let demoURL='https://www.aochenyun.cn'
let demoURL=process.env.VUE_APP_LOOK_URL
export default {
   groupBookingIntercept,
   BuiltInLogin,
   demoURL,
}
