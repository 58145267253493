<template>
  <div class="Footer">
    <!-- <van-tabbar v-model="active" @change="onChange">
      <router-link to="/">
        <van-tabbar-item>
          <span>云手机</span>
          <template #icon="props">
            <img
              :src="
                props.active
                  ? require('../assets/img/homeAmend/phonePitch.png')
                  : require('../assets/img/homeAmend/phoneDefault.png')
              "
              alt=""
            />
          </template>
        </van-tabbar-item>
      </router-link>
      <router-link to="/discover">
        <van-tabbar-item>
          <span>发现</span>
          <template #icon="props">
            <img
              :src="
                props.active
                  ? require('../assets/img/discoverAmend/navIcon_active.png')
                  : require('../assets/img/discoverAmend/navIcon.png')
              "
              alt=""
            />
          </template> </van-tabbar-item
      ></router-link>
      <router-link to="/mine">
        <van-tabbar-item>
          <span>我的</span>
          <template #icon="props">
            <img
              :src="
                props.active
                  ? require('../assets/img/homeAmend/myPitch.png')
                  : require('../assets/img/homeAmend/myDefault.png')
              "
              alt=""
            />
          </template> </van-tabbar-item
      ></router-link>
    </van-tabbar> -->
    <div class="baseNav">
      <div class="NavList">
        <router-link to="/">
          <img
            class="NavImg"
            :src="
              this.$route.path == '/'
                ? require('../assets/img/homeAmend/phonePitch.png')
                : require('../assets/img/homeAmend/phoneDefault.png')
            "
            alt=""
          />
          <div :class="['Navname', this.$route.path == '/' ? 'active' : '']">
            云手机
          </div>
        </router-link>
      </div>
      <div class="NavList">
        <router-link to="/discover">
          <img
            class="NavImg"
            :src="
              this.$route.path == '/discover'
                ? require('../assets/img/discoverAmend/navIcon_active.png')
                : require('../assets/img/discoverAmend/navIcon.png')
            "
            alt=""
          />
          <div
            :class="[
              'Navname',
              this.$route.path == '/discover' ? 'active' : '',
            ]"
          >
            发现
          </div>
        </router-link>
      </div>
      <div class="NavList">
        <router-link to="/mine">
          <img
            class="NavImg"
            :src="
              this.$route.path == '/mine'
                ? require('../assets/img/homeAmend/myPitch.png')
                : require('../assets/img/homeAmend/myDefault.png')
            "
            alt=""
          />
          <div
            :class="['Navname', this.$route.path == '/mine' ? 'active' : '']"
          >
            我的
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AochenFooter",

  data() {
    return {
      active: 0,
    };
  },

  mounted() {
    if (this.$route.path == "/discover") {
      this.active = 1;
    }
    if (this.$route.path == "/mine") {
      this.active = 2;
    }
  },

  methods: {
    onChange() {},
  },
};
</script>

<style lang="less" scoped>
.Footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #fff;
  .baseNav {
    width: 100%;
    height: 100%;
    display: flex;
    .NavList {
      width: 33.3%;
      text-align: center;
      height: 100%;
      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      .NavImg {
        width: 20px;
        margin-bottom: 4px;
      }
      .Navname {
        font-size: 11px;
        color: #aaabad;
        line-height: 13px;
        text-align: center;
      }
      .active {
        color: #0066ff;
      }
    }
  }
}
</style>