import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vant from 'vant';
import 'vant/lib/index.css'
import fullscreen from 'vue-fullscreen'
import scroll from 'vue-seamless-scroll'
import './permission'
import GLOBAL from "../src/utils/global";
import common from '../src/utils/common'
import { Lazyload } from 'vant';
import "amfe-flexible"
//引入store
import store from './store'
import VueTouch from "vue-touch";

Vue.prototype.$global = GLOBAL;
Vue.prototype.$common = common;
Vue.use(scroll)
Vue.use(fullscreen)
Vue.use(vant);
Vue.use(Lazyload, {
  lazyComponent: true,
});
Vue.use(VueTouch, { name: "v-touch" });
VueTouch.config.swipe = {
  threshold: 50  //设置左右滑动的距离
}
Vue.config.productionTip = false
// 节流自定义指令
Vue.directive('throttle', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      el.style.pointerEvents = 'none'
      if (!el.disabled) {
        setTimeout(() => {
          el.style.pointerEvents = 'auto'
        }, binding.value || 300)
      }
    })
  }
})
// 防抖自定义指令
Vue.directive(
  'shake', {
  inserted: function (el, binding) {
    let timer;
    el.addEventListener("click", () => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        binding.value();
      }, 1000);
    });
  },
})

// 定义全局过滤器
Vue.filter('twoDecimals', function (value) {
  if (typeof value !== 'number') {
    return value;
  }
  return value.toFixed(2);
});

var _hmt = _hmt || [];
window._hmt = _hmt;
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?af5fc87d557ae34a5ecf18defbe30667";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
