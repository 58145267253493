<template>
  <div class="Singlecard">
    <!--  :class="current == 2 ? 'endDiactive' : ''" -->
    <div class="mainContent">
      <div class="home_yunshouji">
        <van-swipe
          @change="swipe_indicator"
          class="my-swipe"
          :loop="false"
          indicator-color="white"
          :stop-propagation="false"
          ref="van_swipe"
          :show-indicators="false"
        >
          <van-swipe-item v-for="(item, index) in InstanceList" :key="index">
            <div class="div_img">
              <div class="ysj_name">
                <div class="ysj_head_left">
                  <div class="CloudIconBox">
                    <img
                      v-if="
                        (item.vipCode == 'VIP1') &
                        (item.deviceVersionCode == 'V8')
                      "
                      src="../../assets/icons/biaozhunanzhuo8.png"
                      alt=""
                    />
                    <img
                      v-if="
                        (item.vipCode == 'VIP1') &
                        (item.deviceVersionCode == 'V10')
                      "
                      src="../../assets/icons/biaozhunanzhuo10.png"
                      alt=""
                    />
                    <img
                      v-if="
                        (item.vipCode == 'VIP2') &
                        (item.deviceVersionCode == 'V8')
                      "
                      src="../../assets/icons/changwananzhuo8.png"
                      alt=""
                    />
                    <img
                      v-if="
                        (item.vipCode == 'VIP2') &
                        (item.deviceVersionCode == 'V10')
                      "
                      src="../../assets/icons/changwananzhuo10.png"
                      alt=""
                    />
                    <img
                      v-if="
                        (item.vipCode == 'VIP3') &
                        (item.deviceVersionCode == 'V8')
                      "
                      src="../../assets/icons/aorananzhuo8.png"
                      alt=""
                    />
                    <img
                      v-if="
                        (item.vipCode == 'VIP3') &
                        (item.deviceVersionCode == 'V10')
                      "
                      src="../../assets/icons/aorananzhuo10.png"
                      alt=""
                    />
                    <img
                      v-if="
                        (item.vipCode == 'VIP4') &
                        (item.deviceVersionCode == 'V8')
                      "
                      src="../../assets/icons/zhizunanzhuo8.png"
                      alt=""
                    />
                    <img
                      v-if="
                        (item.vipCode == 'VIP4') &
                        (item.deviceVersionCode == 'V10')
                      "
                      src="../../assets/icons/zhizunanzhuo10.png"
                      alt=""
                    />
                    <img
                      v-if="
                        (item.vipCode != 'VIP1') &
                        (item.vipCode != 'VIP2') &
                        (item.vipCode != 'VIP3') &
                        (item.vipCode != 'VIP4')
                      "
                      src="../../assets/icons/dingzhianzhuo.png"
                      alt=""
                    />
                  </div>
                  <div class="nameItem">
                    <div class="instanceName">
                      <div class="typeName">{{ item.instanceName }}</div>
                      <!-- v-if="item.isAuthorized == 1"  -->
                      <div class="yishouquan" v-if="item.isAuthorized == 2">
                        已授权
                      </div>
                      <div class="beishouquan" v-if="item.isAuthorized == 1">
                        被授权
                      </div>
                    </div>
                    <van-count-down
                      class="instanceItem"
                      :time="item.countDown"
                      :format="
                        item.countDown > 86400000
                          ? '剩余:DD天HH小时mm分钟'
                          : '剩余:HH小时mm分钟ss秒'
                      "
                    />
                  </div>
                </div>
                <div
                  v-if="item.isAuthorized != 1"
                  class="renewRight"
                  @click="xushi(item)"
                >
                  续费
                </div>
              </div>
              <div v-if="item.maintenanceStatus == 1" class="weihu">
                <img
                  class="weihuImg"
                  src="../../assets/img/homeAmend/maintainImg.png"
                  alt=""
                />
                <p class="weihuTitle">正在维护中...</p>
                <div>
                  <p>本云手机正在维护中预计维</p>
                  <p>护时间为：</p>
                  {{ item.maintenanceText }}
                  <p>维护时间结束后请点击刷新按钮</p>
                  <p>建议维护完成 后重启云手机</p>
                  <p>给您带来不便敬请谅解</p>
                </div>
              </div>
              <div
                class="weihu"
                v-else-if="item.overSoldDevice"
                @click="replace(item)"
              >
                <div>
                  <img
                    class="weihuImg"
                    src="../../assets/img/homeAmend/malfunction.png"
                    alt=""
                  />
                  <p style="color: red" class="weihuTitle">云手机故障...</p>
                </div>
                <div>
                  <p>点击自动更换一台</p>
                  <!-- <p>护时间为：</p>
            {{ item.maintenanceText }}
            <p>维护时间结束后请点击刷新按钮</p>
            <p>建议维护完成 后重启云手机</p>
            <p>给您带来不便敬请谅解</p> -->
                </div>
              </div>
              <div v-else @click="BgsSdk_child(item, index)" class="feiweihu">
                <img
                  class="imgs"
                  v-if="
                    shotinfoList && shotinfoList.length != 0 && imageurl(item)
                  "
                  :src="imageurl(item)"
                  alt=""
                />
                <img
                  v-else
                  class="imgs"
                  src="../../assets/img//homeAmend/loadLine.png"
                  alt=""
                />
              </div>
              <!-- 分组续费提示 -->
              <div
                class="remind"
                v-if="
                  (item.isGroupRenew == true &&
                    (item.isDisplay == 0 ||
                      (item.isDisplay != 0 &&
                        item.groupRenewActivityTime > 0)) &&
                    item.remind < 3 &&
                    item.statusShow &&
                    item.isAuthorized != 1) ||
                  (item.isGroupRenew == true &&
                    item.remind < 1 &&
                    item.statusShow &&
                    item.isAuthorized != 1)
                "
              >
                <img
                  @click="bad(index, item)"
                  class="x_remind"
                  src="../../assets/img/homeAmend/remind_x.png"
                  alt=""
                />
                <div :class="index == current ? 'shake-text' : ''">
                  <div class="title_remind">到期提醒</div>
                  <div class="ecialText_remind">
                    云手机时长不足{{
                      item.remind + 1
                    }}天，到期将清空数据<br />送您一个惊喜续费特权，享超低价续费！
                  </div>
                  <img
                    @click="specialPageRemind(item)"
                    class="privilegeButton"
                    src="../../assets/img/homeAmend/privilegeRenewButton.png"
                    alt=""
                  />
                </div>
              </div>
              <!-- 跳收银台3天内到期提醒ui -->
              <div
                class="remind"
                v-else-if="
                  item.remind < 3 && item.statusShow && item.isAuthorized != 1
                "
              >
                <img
                  @click="bad(index, item)"
                  class="x_remind"
                  src="../../assets/img/homeAmend/remind_x.png"
                  alt=""
                />
                <div :class="index == current ? 'shake-text' : ''">
                  <div class="title_remind">到期提醒</div>
                  <div class="text_remind" @click="pageRemind(item)">
                    云手机时长<span>不足{{ item.remind + 1 }}天</span
                    >，到期将清空数据<br />
                    <span>送您一个续费优惠</span>，点击立即领取使用
                  </div>
                </div>
              </div>
              <div
                class="sevenExpiration"
                v-if="
                  item.remind >= 3 &&
                  item.remind < 7 &&
                  item.statusShow &&
                  item.isAuthorized != 1
                "
              >
                <img
                  class="FrameName"
                  src="../../assets/img/homeAmend/Frame.png"
                  alt=""
                />
                <div class="text" @click="pageRemind(item)">
                  云手机时长不足{{
                    item.remind + 1
                  }}天，到期将清空数据，请及时<span>续费</span>
                </div>
                <img
                  @click="bad(index, item)"
                  class="sevenX"
                  src="../../assets/img/homeAmend/sevenX.png"
                  alt=""
                />
              </div>
              <img
                @click="popupshow(item)"
                class="seting"
                src="../../assets/icons/settingm_g.png"
                alt=""
              />
            </div>
          </van-swipe-item>
          <van-swipe-item v-if="wealShow">
            <div class="div_img newWeal" @click="newWeal">
              <button class="ljgm_btn">免费领取</button>
            </div>
          </van-swipe-item>
          <van-swipe-item v-if="buyShow">
            <div class="div_img lijilingqu" @click="lijilingqu">
              <button class="ljgm_btn">立即领取</button>
            </div>
          </van-swipe-item>
          <van-swipe-item v-if="getShow">
            <div class="div_img lijigoumai" @click="lijigoumai">
              <!-- <button class="ljgm_btn">立即购买</button> -->
              <img
                class="addImg1"
                src="../../assets/img/homeAmend/addCloudPhone.png"
                alt=""
              />
              <img
                class="addImg2"
                src="../../assets/img/homeAmend/addText.png"
                alt=""
              />
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <Aochenpeizhi :Aochen_phone="aochenphone" ref="aochen_peizhis" />
    <detainmentDialog
      v-model="showDialog"
      width="298"
      confimText="确认"
      cancelText="取消"
      cancelType="primary"
      :confimBtnLoading="confimBtnLoading"
      @confim="confirmModel"
      @change="changeModel"
      title="领取云手机"
    >
      <div class="contentBox">
        <img
          @click="captchaChange"
          class="captchaImg"
          :src="'data:image/png;base64,' + captchaImg"
          alt=""
        />
        <div class="captchaName">看不清？点击图片换一张</div>
        <van-cell-group
          :border="false"
          :class="jiaodian ? 'huoqujiaodian' : 'shiqujiaodian'"
        >
          <van-field
            v-model="captchaValue"
            type="tel"
            placeholder="请输入验证码"
            @focus="huoqu"
            @blur="shiqu"
            maxlength="11"
          />
        </van-cell-group>
      </div>
    </detainmentDialog>
  </div>
</template>

<script>
import {
  regWelfare,
  sysConfigChannel,
  captchaImage,
  activityReceive,
  hsOrBdScreenshot,
  hsOrBdScreenshot_info,
} from "@/api/index.js";
import { Toast } from "vant";
import Aochenpeizhi from "@/components/cell/Aochen_peizhi.vue";
import detainmentDialog from "@/components/publicModule/detainmentDialog.vue";
export default {
  name: "AochenyunshoujiSinglecard",
  components: {
    Aochenpeizhi,
    detainmentDialog,
  },
  props: {
    InstanceList: {
      default: null,
      type: Array,
    },
    gonggeshow: {
      default: null,
      type: Number,
    },
    buyShow: {
      default: false,
      type: Boolean,
    },
    getShow: {
      default: false,
      type: Boolean,
    },
  },
  watch: {
    gonggeshow(news, olds) {
      let that = this;
      if (news == 1) {
        if (that.userdata) {
          that.getscreenshot();
          that.screen_Interval = setInterval(function () {
            that.getscreenshot();
          }, this.refreshSpeed);
        }
      } else {
        clearInterval(that.screen_Interval);
        that.screen_Interval = null;
      }
    },
    $route(to, from) {
      if (to) {
        const Content = document.querySelectorAll(".mainContent");
        if (Content[0]) {
          Content[0].classList.remove("endDiactive");
        }
      }
    },
  },
  data() {
    return {
      current: 0,
      screenshotList: [],
      shotinfoList: [],
      screen_Interval: null,
      screen_Timeout: null,
      swiper_stop: true,
      userdata: null,
      aochenphone: null,
      wealShow: false,
      showDialog: false,
      confimBtnLoading: false,
      captchaImg: "",
      captchaUuId: "",
      jiaodian: false,
      captchaValue: "",
      refreshSpeed:JSON.parse(localStorage.getItem("refreshSpeed"))||2000
    };
  },

  mounted() {
    // this.downTime()
    let that = this;
    that.userdata = JSON.parse(localStorage.getItem("data"));
    setTimeout(() => {
      this.screen_Interval = null;
      that.swipe_indicator(0);
      // that.getscreenshot();
    }, 250);
    // 监听页面不可见，清除定时器
    document.addEventListener("visibilitychange", this.visibility);
    if (this.InstanceList.length <= 0) {
      that.getRegWelfare();
    }
  },
  beforeDestroy() {
    clearInterval(this.screen_Interval);
    this.screen_Interval = null;
    document.removeEventListener("visibilitychange", this.visibility);
  },
  computed: {
    imageurl: {
      get() {
        return function (em) {
          if (em) {
            for (let i = 0; i < this.shotinfoList.length; i++) {
              if (em.instanceCode == this.shotinfoList[i].instanceCode) {
                return this.shotinfoList[i].url;
              }
            }
          }
        };
      },
    },
  },

  methods: {
    // 监听切换到后台和手机息屏，关闭截图
    visibility() {
      let that = this;
      if (document.hidden) {
        console.log("H5已切换到后台或手机息屏");
        clearInterval(this.screen_Interval);
        this.screen_Interval = null;
      } else {
        console.log("H5已切换到网页");
        clearInterval(this.screen_Interval);
        this.screen_Interval = null;
        this.screen_Interval = setInterval(function () {
          that.getscreenshot();
        }, this.refreshSpeed);
      }
    },
    // 倒计时全局方法，使用组件，已废弃
    // downTime() {
    //   for (let i = 0; i < this.InstanceList.length; i++) {
    //     console.log(this.$common.countTime(this.InstanceList[i].endTime));
    //   }
    // },
    // 差掉时间不足提醒，并存到sessionStorage
    bad(index, item) {
      this.InstanceList[index].statusShow = false;
      let Show = JSON.parse(sessionStorage.getItem("countDownShow"));
      if (Show == null) {
        let _show = [];
        _show.push({ id: item.id, expireSeconds: item.expireSeconds });
        sessionStorage.setItem("countDownShow", JSON.stringify(_show));
      } else {
        // 查找或替换
        let findIndex = Show.findIndex((list) => list.id === item.id);
        if (findIndex !== -1) {
          Show[findIndex] = { id: item.id, expireSeconds: item.expireSeconds }; // 替换
        } else {
          Show.push({ id: item.id, expireSeconds: item.expireSeconds }); // 增加
        }
        sessionStorage.setItem("countDownShow", JSON.stringify(Show));
      }
      this.$forceUpdate();
    },
    // 跳到收银台续费
    pageRemind(em) {
      if (em.overSoldDevice) {
        this.$refs.aochen_peizhis.warningShow();
      } else {
        this.$parent.getinstancelist();
        sessionStorage.setItem("renewal", JSON.stringify(em));
        this.$router.push({
          path: "/renewaltime",
        });
      }
    },
    // 跳转ab分组续费
    specialPageRemind(em) {
      if (em.overSoldDevice) {
        this.$refs.aochen_peizhis.warningShow();
      } else {
        this.$parent.getinstancelist();
        sessionStorage.setItem("renewal", JSON.stringify(em));
        this.$router.push({
          params: {
            e: em,
          },
          name: "TimeIimitrenew",
        });
      }
    },
    // 启动云手机
    BgsSdk_child(em, dex) {
      clearInterval(this.screen_Interval);
      this.screen_Interval = null;
      this.swiper_stop = false;
      this.$parent.BgsSdca(em, dex);
    },
    popupshow(em) {
      this.aochenphone = em;
      this.$refs.aochen_peizhis.popupshows();
    },
    // 超卖更换设备
    replace(em) {
      this.aochenphone = em;
      this.$refs.aochen_peizhis.querengenghuanshebei();
    },
    // 新人福利
    newWeal() {
      this.getCaptchaImage();
      this.showDialog = true;
    },
    // 立即领取
    lijilingqu() {
      this.userdata = JSON.parse(localStorage.getItem("data"));
      if (this.userdata) {
        this.$router.push("/newcomerexperience");
      } else {
        this.$router.push("/login");
      }
    },
    // 立即购买
    lijigoumai() {
      this.userdata = JSON.parse(localStorage.getItem("data"));
      if (this.userdata) {
        // this.$router.push("/AddCloudPhone");
        this.$router.push({ path: "/newcloudphone", query: { VIP: 1 } });
      } else {
        this.$router.push("/login");
      }
    },
    // 获取当前轮播页
    swipe_indicator(e) {
      let that = this;
      that.current = e;
      that.swiper_change(e);
      if (that.current >= that.InstanceList.length) {
        clearInterval(that.screen_Interval);
        that.screen_Interval = null;
      }
      if (that.screen_Interval == null) {
        that.getscreenshot();
        that.screen_Interval = setInterval(function () {
          that.getscreenshot();
        }, this.refreshSpeed);
      }
    },
    // 轮播卡片大小
    // swiper_change(e) {
    //   const diimg = document.querySelectorAll(".div_img");
    //   for (let i = 0; i < diimg.length; i++) {
    //     diimg[i].classList.remove("diactive");
    //     if (e == i) {
    //       diimg[e].classList.add("diactive");
    //     }
    //   }
    // },
    //  最后一个图片单独设置css
    swiper_change(e) {
      const Content = document.querySelectorAll(".mainContent");
      const diimg = document.querySelectorAll(".div_img");
      if (Content[0]) {
        Content[0].classList.remove("endDiactive");
      }
      if (e == diimg.length - 1 && Content[0]) {
        Content[0].classList.add("endDiactive");
      }
    },
    // 获取设备截图
    async getscreenshot() {
      let that = this;
      if (that.InstanceList[that.current]) {
        if (
          !that.InstanceList[that.current].overSoldDevice &&
          that.InstanceList[that.current].maintenanceStatus != 1
        ) {
          let data = {
            instanceCodes: [that.InstanceList[that.current].instanceCode],
            quality: 3,
          };
          await hsOrBdScreenshot(data).then((res) => {
            if (res.code == 200) {
              res.data.forEach((item) => {
                if (item.taskId) {
                  that.screenshotList = res.data;
                  that.screen_Timeout = setTimeout(function () {
                    that.getscreenshotinfo();
                  }, 1000);
                } else {
                  that.shotinfoList = that.mergeArrayById(
                    that.shotinfoList,
                    res.data
                  );
                }
              });
            } else {
              Toast(res.msg);
              if (res.code == 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("data");
              }
            }
          });
        } else {
          clearInterval(this.screen_Interval);
          this.screen_Interval = null;
        }
      } else {
        clearInterval(that.screen_Interval);
        that.screen_Interval = null;
      }
    },
    // 获取截图结果
    async getscreenshotinfo() {
      let that = this;
      if (that.screenshotList[0]) {
        let data = [
          {
            instanceCode: that.screenshotList[0].instanceCode,
            taskId: that.screenshotList[0].taskId,
          },
        ];
        await hsOrBdScreenshot_info(data).then((res) => {
          if (res.code == 200) {
            that.shotinfoList = that.mergeArrayById(
              that.shotinfoList,
              res.data
            );
          } else {
            // Toast(res.msg);
          }
        });
      }
    },
    // 截图缓存
    mergeArrayById(arr1, arr2) {
      arr2.forEach((item2) => {
        const index = arr1.findIndex(
          (item1) => item1.instanceCode === item2.instanceCode
        );
        if (index !== -1) {
          // 如果在arr1中找到了相同的id，且url不等于false则替换该元素
          if (item2.url) {
            this.$set(arr1, index, item2);
          }
        } else {
          // 如果在arr1中没有找到相同的id，则添加该元素
          arr1.push(item2);
        }
      });
      return arr1;
    },

    xushi(em) {
      if (em.overSoldDevice) {
        this.$refs.aochen_peizhis.warningShow();
      } else {
        sessionStorage.setItem("renewal", JSON.stringify(em));
        this.$router.push({
          path: "/renewaltime",
        });
      }
    },
    // 获取是否领取过注册福利
    getRegWelfare() {
      regWelfare().then((res) => {
        if (res.code == 200) {
          if (res.data.type == "0") {
            // let { loginType } = JSON.parse(localStorage.getItem("data"));
            // if (loginType == "4") {
            //   this.wealShow = true;
            // } else {
            //   this.getSysConfigChannel();
            // }
            this.getSysConfigChannel();
          }
        }
      });
    },
    // 获取是否匹配渠道
    getSysConfigChannel() {
      let { channelCode } = JSON.parse(sessionStorage.getItem("channelCode"));
      sysConfigChannel().then((res) => {
        if (res.code == 200) {
          res.data.forEach((item) => {
            if (
              item.toLowerCase() == channelCode.toLowerCase() ||
              item.toLowerCase() == "all"
            ) {
              this.wealShow = true;
            }
          });
        }
      });
    },
    getCaptchaImage() {
      captchaImage().then((res) => {
        if (res.code == 200) {
          this.captchaImg = res.data.img;
          this.captchaUuId = res.data.uuid;
        }
      });
    },
    captchaChange() {
      this.getCaptchaImage();
    },
    huoqu() {
      this.jiaodian = true;
    },
    shiqu() {
      this.jiaodian = false;
    },
    changeModel() {
      this.showDialog = false;
      this.captchaValue = "";
    },
    confirmModel() {
      activityReceive({
        uuid: this.captchaUuId,
        code: this.captchaValue,
      }).then((res) => {
        if (res.code == 200) {
          Toast("领取成功");
          this.changeModel();
          this.$router.go(0);
        } else {
          this.changeModel();
          Toast(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.shake-text {
  // display: inline-block;
  // white-space: nowrap;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  font-size: 20px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.mainContent {
  display: flex;
  // align-items: center;
  // justify-content: center;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 30px;
  min-height: 584px;
  overflow: hidden;
}
.home_yunshouji {
  // margin-left: 14px;
  padding: 32px 0 36px;
  width: 320px;
  height: 498px;
  /deep/ .van-swipe {
    overflow: visible;
  }
  .van-swipe-item {
    display: flex;
    align-items: center;
    height: 498px;
    width: 320px;
  }
  .div_img {
    position: relative;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    width: 300px;
    height: 534px;
    border-radius: 30px;
    border: 2px solid #999999;
    margin-left: 14px;
    margin-right: 15px;
    .ysj_name {
      position: absolute;
      left: 0;
      right: 0;
      height: 50px;
      background: #000;
      border-radius: 14px;
      opacity: 0.6;
      width: calc(100% - 32px);
      margin: 0 auto;
      margin-top: 16px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      z-index: 1;
      box-sizing: border-box;
      padding: 0 12px;
      .ysj_head_left {
        display: flex;
        .CloudIconBox {
          width: 30px;
          height: 30px;
          background: #eefdff;
          border-radius: 15px 15px 15px 15px;
          line-height: 35px;
          text-align: center;
          img {
            width: 16px;
            height: 16px;
          }
        }
        .nameItem {
          text-align: left;
          margin-left: 8px;
          display: flex;
          flex-direction: column;
          .instanceName {
            font-size: 12px;
            font-weight: 400;
            color: #ffffff;
            line-height: 16px;
            margin-bottom: 4px;
            .typeName {
              float: left;
            }
          }
          .instanceItem {
            font-size: 10px;
            font-weight: 400;
            color: #b3b3b3;
            line-height: 10px;
          }
        }
      }
      .renewRight {
        width: 52px;
        height: 26px;
        background: linear-gradient(154deg, #00c2ff 0%, #0066ff 100%);
        border-radius: 24px;
        line-height: 27px;
        text-align: center;
      }
      span {
        text-align: left;
      }
      .yishouquan {
        float: left;
        width: 30px;
        height: 12px;
        background: #3ac984;
        border-radius: 2px 6px 6px 6px;
        display: inline-block;
        font-size: 8px;
        text-align: center;
        line-height: 12px;
        margin-left: 2px;
        margin-top: 2px;
      }
      .beishouquan {
        background: #ff2828;
        float: left;
        width: 30px;
        height: 12px;
        border-radius: 2px 6px 6px 6px;
        display: inline-block;
        font-size: 8px;
        text-align: center;
        line-height: 12px;
        margin-left: 2px;
        margin-top: 2px;
      }
      p {
        flex: 1;
      }
    }
    .remind {
      position: absolute;
      bottom: 74px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 268px;
      // height: 120px;
      background: #000;
      border-radius: 14px;
      opacity: 0.7;
      .x_remind {
        float: right;
        width: 18px;
        height: 18px;
        padding: 12px 12px 0 0;
      }
      .title_remind {
        font-size: 17px;
        font-weight: 500;
        color: #fff;
        margin: 18px 0 16px 0;
        text-align: center;
        padding-left: 30px;
      }
      .ecialText_remind {
        font-size: 14px;
        color: #fff;
        text-align: left;
        display: flex;
        justify-content: center;
        span {
          color: #0099ff;
          font-size: 13px;
        }
      }
      .text_remind {
        font-size: 14px;
        color: #fff;
        padding-bottom: 22px;
        span {
          color: #0099ff;
          font-size: 13px;
        }
      }
      .privilegeButton {
        width: 140px;
        height: 36px;
        margin-top: 16px;
        margin-bottom: 24px;
      }
    }
    .sevenExpiration {
      position: absolute;
      bottom: 74px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 264px;
      height: 54px;
      background: #000;
      border-radius: 14px;
      opacity: 0.6;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .FrameName {
        width: 36px;
        height: 36px;
        margin-right: 6px;
        margin-left: 12px;
      }
      .text {
        font-size: 12px;
        color: #fff;
        text-align: left;
        line-height: 18px;
        width: 170px;
        span {
          color: #0099ff;
        }
      }
      .sevenX {
        width: 18px;
        height: 18px;
        padding-right: 12px;
      }
    }
    .seting {
      position: absolute;
      bottom: 16px;
      right: 16px;
      width: 46px;
      height: 46px;
    }
    .imgs {
      height: 100%;
      overflow: hidden;
      border-radius: 10px;
    }
    .weihu {
      width: calc(100% - 0px);
      height: 100%;
      background-size: 140px 140px;
      // position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      .weihuImg {
        width: 120px;
      }
      .weihuTitle {
        font-size: 20px;
        font-weight: 400;
        color: #0066ff;
        line-height: 24px;
        margin-bottom: 10px;
      }
      p {
        margin: 4px 0;
        color: rgb(74, 74, 74);
        font-family: 思源黑体;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: center;
      }
    }
    .feiweihu {
      height: 100%;
    }
  }
  .diactive {
    margin: 0;
    height: 498px;
    transition: 0.3s ease-in-out;
    transform: scale(1.1);
  }
  .custom_indicator {
    margin-top: 30px;
  }
}
.endDiactive {
  // margin-left: 40px !important;
  justify-content: flex-end !important;
  transition: 1s ease-in-out;
}
.newWeal {
  background-image: url("../../assets/img/homeAmend/newWeal.png");
  background-size: 100% 100%;
}
.lijilingqu {
  background-image: url("../../assets/img/homeAmend/newPeopleBack.png");
  background-size: 100% 100%;
  margin: 0 14px !important;
}
.lijigoumai {
  background-image: url("../../assets/img/homeAmend/headBack.png");
  background-size: 100% 100%;
  // margin: 0 12px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .addImg1 {
    width: 72px;
    margin-bottom: 20px;
  }
  .addImg2 {
    width: 110px;
  }
}
.ljgm_btn {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  border: none;
  margin: 0 auto;
  color: #fff;
  width: calc(100% - 186px);
  width: 134px;
  height: 44px;
  background: linear-gradient(154deg, #00c2ff 0%, #0066ff 100%);
  border-radius: 22px;
  font-size: 17px;
}
.contentBox {
  color: #3674fa;
  box-sizing: border-box;
  padding: 0 30px;
  .captchaImg {
    width: 100%;
  }
  .captchaName {
    font-size: 12px;
    margin: 6px 0;
  }
  .van-cell {
    padding: 10px 5px;
    font-size: 16px;
  }
}
.shiqujiaodian {
  border-bottom: 1px solid #f4f5f9;
  border-top: 0px;
}
.huoqujiaodian {
  border-bottom: 1px solid #558bff;
}
</style>