import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const server = [
    {
        path: '/activationconversion',
        name: 'ActivationCode',
        component: () => import('../../views/Activationcode/ActivationCode.vue')
    },
    {
        path: '/activationcodeusage',
        name: 'ActivationCodeUsage',
        component: () => import('../../views/Activationcode/ActivationCodeUsage.vue')
    },
    {
        path: '/activation_result',
        name: 'ActivationResult',
        component: () => import('../../views/Activationcode/ActivationResult.vue')
    },
    {
        path: '/anacthasbeenadd',
        name: 'Anacthasbeenadd',
        component: () => import('../../views/Activationcode/Anacthasbeenadd.vue')
    },
    {
        path: '/choosecloudphone',
        name: 'Choosecloudphone',
        component: () => import('../../views/Activationcode/Choosecloudphone.vue')
    },
    {
        path: '/choosecontinuation',
        name: 'Choosecontinuation',
        component: () => import('../../views/Activationcode/Choosecontinuation.vue')
    }, {
        path: '/selectedphonebeen',
        name: 'Selectedphonebeen',
        component: () => import('../../views/Activationcode/Selectedphonebeen.vue')
    },
]

export default server
